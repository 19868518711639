import * as React from 'react';
const History = (props) => (
    <svg
        stroke='currentColor'
        fill={props.fill ?? '#8993A4'}
        strokeWidth={props.strokeWidth ?? 0}
        height={props.height ?? '16px'}
        width={props.width ?? '16px'}
        id='history'
        name='history'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        xmlSpace='preserve'
        {...props}
    >
        <g id='history'>
            <g>
                <path d='M12,24C5.4,24,0,18.6,0,12h2c0,5.5,4.5,10,10,10s10-4.5,10-10S17.5,2,12,2C8.4,2,5.1,3.9,3.3,7H8v2H0V1h2v4.4 C4.2,2.1,8,0,12,0c6.6,0,12,5.4,12,12S18.6,24,12,24z M15.3,17.8L11,13.4V6h2v6.6l3.7,3.8L15.3,17.8z' />
            </g>
        </g>
    </svg>
);
export default History;
