import React from 'react';
import PropTypes from 'prop-types';

const Email = ({ width = 16, height = 16 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 312 313'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g>
                <path
                    d='M283.359 44.1771H28.6406C21.0473 44.1856 13.7674 47.2059 8.39809 52.5752C3.02878 57.9445 0.00854825 65.2244 0 72.8177L0 241.153C0.00854825 248.746 3.02878 256.026 8.39809 261.395C13.7674 266.765 21.0473 269.785 28.6406 269.793H283.359C290.953 269.785 298.233 266.765 303.602 261.395C308.971 256.026 311.991 248.746 312 241.153V72.8177C311.991 65.2244 308.971 57.9445 303.602 52.5752C298.233 47.2059 290.953 44.1856 283.359 44.1771ZM205.951 156.985L296.053 66.8836C296.925 68.7401 297.377 70.7664 297.375 72.8177V241.153C297.377 243.204 296.925 245.23 296.053 247.087L205.951 156.985ZM283.359 58.8021H283.449L156 186.253L28.551 58.8045H28.6406L283.359 58.8021ZM15.9473 247.087C15.0751 245.23 14.6235 243.204 14.625 241.153V72.8177C14.6234 70.7664 15.0749 68.7401 15.9473 66.8836L106.049 156.985L15.9473 247.087ZM28.6406 255.168C28.6108 255.168 28.5797 255.168 28.5504 255.168L116.391 167.326L150.83 201.766C152.201 203.137 154.061 203.907 156.001 203.907C157.94 203.907 159.8 203.137 161.171 201.766L195.609 167.327L283.449 255.166C283.419 255.166 283.388 255.166 283.359 255.166L28.6406 255.168Z'
                    fill='#8993A4'
                />
            </g>
            <defs>
                <clipPath id='clip0_777_10767'>
                    <rect width='312' height='312' fill='white' transform='translate(0 0.985199)' />
                </clipPath>
            </defs>
        </svg>
    );
};

Email.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};

export default Email;
