import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import {
    Landing,
    ServerErrorPage,
    PageNotFound,
    Notification,
    EditProfile,
} from './components/shared';
import {
    Login,
    Signup,
    ForgetPassword,
    HiringClientListing,
    DocumentListing as DocumentListingCustomer,
    CustomerDashboard,
    ScoreCardReport,
    ResetPassword,
    DigitalSafetyLibraryCustomer,
} from './components/Customer';
import {
    SSOLogin,
    AdminDashboard,
    DocumentCategoryListing,
    CsmListing,
    CustomerListing,
    DocumentListing,
    AdminListing,
    ScoreCardReportAdmin,
    NewsFeed,
    AdminScoreCardListing,
    DocumentRequestView,
    RegulatoryIssue,
    EditCustomer,
    CSMHiringClientOrder,
    ViewUser,
    DigitalSafetyLibrary,
} from './components/Admin';
import { LOCAL_STORAGE, ROUTES } from './constants';
import { getLocalStorageItem } from './utils';
import LegendPage from './components/shared/common/LegendPage';

const AdminAuthGuard = () => {
    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);
    return userRole === null ? <SSOLogin /> : <Navigate to={ROUTES.ADMIN.DEFAULT} replace />;
};
const CustomerAuthGuard = () => {
    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);
    return userRole === null ? <Login /> : <Navigate to={ROUTES.CUSTOMER.DEFAULT} replace />;
};

const adminRouter = createBrowserRouter([
    {
        path: ROUTES.HOME,
        element: <Landing />,
        children: [
            {
                path: ROUTES.ADMIN.LOGIN,
                element: <AdminAuthGuard />,
            },
            {
                path: ROUTES.EMPTY,
                element: <AdminAuthGuard />,
            },
        ],
    },
    {
        path: ROUTES.ADMIN.DEFAULT,
        element: <AdminDashboard />,
        children: [
            {
                path: ROUTES.ADMIN.DASHBOARD,
                element: (
                    <AuthenticatedTemplate>
                        <AdminDashboard />
                    </AuthenticatedTemplate>
                ),
            },
            {
                path: ROUTES.ADMIN.DOCUMENT_CATEGORY,
                element: <DocumentCategoryListing />,
            },
            {
                path: ROUTES.ADMIN.DOCUMENTS,
                element: <DocumentListing />,
            },
            {
                path: ROUTES.ADMIN.ADMIN,
                element: <AdminListing />,
            },
            {
                path: ROUTES.ADMIN.CSM,
                element: <CsmListing />,
            },
            {
                path: ROUTES.ADMIN.CUSTOMER,
                element: <CustomerListing />,
            },
            {
                path: ROUTES.ADMIN.CUSTOMER_EDIT,
                element: <EditCustomer />,
            },
            {
                path: ROUTES.ADMIN.USER_VIEW,
                element: <ViewUser />,
            },
            {
                path: ROUTES.ADMIN.DOCUMENT_REQUEST,
                element: <DocumentRequestView />,
            },
            {
                path: ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN,
                element: <ScoreCardReportAdmin />,
            },
            {
                path: ROUTES.ADMIN.NEWS_FEED_ADMIN,
                element: <NewsFeed />,
            },
            {
                path: ROUTES.ADMIN.NOTIFICATION,
                element: <Notification />,
            },
            {
                path: ROUTES.ADMIN.ADMIN_SCORE_CARD_LISTING,
                element: <AdminScoreCardListing />,
            },
            {
                path: ROUTES.ADMIN.EDIT_PROFILE,
                element: <EditProfile />,
            },
            {
                path: ROUTES.ADMIN.ADMIN_REGULATORY_ISSUE,
                element: <RegulatoryIssue />,
            },
            {
                path: ROUTES.ADMIN.CUSTOMER_HIRING_CLIENT_ORDER,
                element: <CSMHiringClientOrder />,
            },
            {
                path: ROUTES.ADMIN.DIGITAL_SAFETY_LIBRARY,
                element: <DigitalSafetyLibrary />,
            },
            {
                path: ROUTES.ADMIN.LEGEND,
                element: <LegendPage />,
            },
        ],
    },
    {
        path: ROUTES.SERVER_ERROR,
        element: <ServerErrorPage />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]);
const customerRouter = createBrowserRouter([
    {
        path: ROUTES.HOME,
        element: <Landing />,
        children: [
            {
                path: ROUTES.CUSTOMER.LOGIN,
                element: <CustomerAuthGuard />,
            },
            {
                path: ROUTES.EMPTY,
                element: <CustomerAuthGuard />,
            },
            {
                path: ROUTES.CUSTOMER.SIGNUP,
                element: <Signup />,
            },
            {
                path: ROUTES.CUSTOMER.FORGOT_PASSWORD,
                element: <ForgetPassword />,
            },
            {
                path: ROUTES.CUSTOMER.RESET_PASSWORD,
                element: <ResetPassword />,
            },
        ],
    },
    {
        path: ROUTES.CUSTOMER.DEFAULT,
        element: <CustomerDashboard />,
        children: [
            {
                path: ROUTES.CUSTOMER.DASHBOARD,
                element: <CustomerDashboard />,
            },
            {
                path: ROUTES.CUSTOMER.DOCUMENTS,
                element: <DocumentListingCustomer />,
            },
            {
                path: ROUTES.CUSTOMER.SCORE_CARD_REPORT,
                element: <ScoreCardReport />,
            },
            {
                path: ROUTES.CUSTOMER.HIRING_CLIENTS,
                element: <HiringClientListing />,
            },
            {
                path: ROUTES.CUSTOMER.NOTIFICATION,
                element: <Notification />,
            },
            {
                path: ROUTES.CUSTOMER.EDIT_PROFILE,
                element: <EditProfile />,
            },
            {
                path: ROUTES.CUSTOMER.DIGITAL_SAFETY_LIBRARY,
                element: <DigitalSafetyLibraryCustomer />,
            },
            {
                path: ROUTES.CUSTOMER.LEGEND,
                element: <LegendPage />,
            },
        ],
    },
    {
        path: ROUTES.SERVER_ERROR,
        element: <ServerErrorPage />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]);

const getRouter = () => {
    const isSubdomainAdmin = window.location.hostname.startsWith(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_ADMIN_DOMAIN,
    );
    return isSubdomainAdmin ? adminRouter : customerRouter;
};

export const router = getRouter();

export default function AppRouter() {
    return <RouterProvider router={router} />;
}
