import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { INFO, ROLES, USER } from '../../../constants';
import { popup, impersonateUser, getAccountByID } from '../../../utils';

import { ImpersonateUserIcon } from '../../../assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { useLocation } from 'react-router-dom';
import { Heading } from '../EditCustomer/Heading';

export const ViewUser = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const id = location?.state?.id || '';
    const role = useSelector((s) => s?.user?.role);
    const [users, setUsers] = useState([]);
    const [accountData, setAccountData] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                dispatch(setLoaderVisibility(true));
                const response = await getAccountByID({ id });
                setAccountData(response.data ?? '');
                setUsers(response?.data?.account?.users ?? []);
            } catch (error) {
                popup('error', error.message);
            } finally {
                dispatch(setLoaderVisibility(false));
            }
        };
        if (id) {
            fetchUsers();
        }
    }, [id]);

    const impersonateHandler = async (user) => {
        if (role === 'csm') {
            try {
                dispatch(setLoaderVisibility(true));
                const { data = {} } = await impersonateUser({
                    userId: user.id,
                });
                const queryString = new URLSearchParams(data).toString();
                // eslint-disable-next-line no-undef
                const impersonateBaseUrl = process.env.REACT_APP_CUSTOMER_PORTAL_LINK;
                window.open(`${impersonateBaseUrl}/customer/dashboard?${queryString}`, '_blank');
            } catch (error) {
                popup('error', error.message);
            } finally {
                dispatch(setLoaderVisibility(false));
            }
        } else {
            popup('error', 'Only an admin/csm can impersonate to user');
        }
    };

    return (
        <div className='edit-box-customer'>
            <Heading data={accountData || ''} heading='Users' />
            <div className='row'>
                <div className='col-12'>
                    <div className='ps-lg-3 d-flex flex-column'>
                        <div className='tabs-set'>
                            <div className={`h-auto  mt-4`}>
                                <div className='scroll-create-user'>
                                    {users.length === 0 && (
                                        <div
                                            className='mt-4 vh-50 d-flex justify-content-center'
                                            style={{ fontSize: '16px' }}
                                        >
                                            {INFO.NO_USER_FOUND}
                                        </div>
                                    )}
                                    {users?.length !== 0 && (
                                        <table className='table mt-3'>
                                            <tbody>
                                                {users
                                                    .filter((user) => user.code !== ROLES.CSM)
                                                    .map((user, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span>{index + 1}.</span>
                                                            </td>
                                                            <td>{user.full_name}</td>
                                                            <td>{user.email}</td>
                                                            <td>
                                                                {user.is_primary === 1
                                                                    ? USER.PRIMARY_USER_BUTTON
                                                                    : USER.SECONDARY_USER}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    title={'Impersonate this user'}
                                                                    className='d-flex rounded-circle align-items-center justify-content-center'
                                                                    style={{
                                                                        width: 32,
                                                                        height: 32,
                                                                    }}
                                                                    id='impersonate-icon'
                                                                    onClick={() =>
                                                                        impersonateHandler(user)
                                                                    }
                                                                >
                                                                    <ImpersonateUserIcon />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ViewUser.propTypes = {
    data: PropTypes.object,
    setReload: PropTypes.func,
};
